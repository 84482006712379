import React from "react";
import { Switch, Route } from "react-router-dom";
import { DataProvider } from "./DataProvider";
import About from "./AboutPage/About";
import VisualizationPage from "./VisualizationPage/VisualizationPage";
import CostModule from "./CostModule/CostModule";
import { VisualizationType } from "./Utils";

const RouteSwitch = () => {
  return (
    <DataProvider>
      <Switch>
        <Route exact path="/cost-module">
          <VisualizationPage visualizationType={VisualizationType.CostModule} />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/">
          <VisualizationPage
            visualizationType={VisualizationType.ScatterPlot}
          />
        </Route>
      </Switch>
    </DataProvider>
  );
};

export default RouteSwitch;
