import React, { useState, createContext } from "react";
import InitialSliderConfigData from "./Configs/Dataset1/dataset1_sliderBarSettings.json";
import SecondarySliderConfigData from "./Configs/Dataset2/dataset2_sliderBarSettings.json";
import InitialSliderPresets from "./Configs/Dataset1/dataset1_sliderBarPresets.json";
import SecondarySliderPresets from "./Configs/Dataset2/dataset2_sliderBarPresets.json";
import InitialDataset from "./Configs/Dataset1/dataset1_vehSummaries.json";
// TODO: replace with secondary dataset when available
// import SecondaryDataset from "./kh_2021_12_09/dataset2_vehSummaries.json";
import InitialGeneralSettings from "./Configs/Dataset1/dataset1_generalSettings.json";
import SecondaryGeneralSettings from "./Configs/Dataset2/dataset2_generalSettings.json";

import Dataset3GeneralSettings from "./Configs/Dataset3/dataset3_generalSettings.json";
import Dataset3SliderPresets from "./Configs/Dataset3/dataset3_sliderBarPresets.json";
import Dataset3SliderConfigData from "./Configs/Dataset3/dataset3_sliderBarSettings.json";
import Dataset3 from "./Configs/Dataset3/dataset3_vehSummaries.json";

import _ from "lodash";

import DatasetConfig from "./Configs/dataset_config.json";

export const DataContext = createContext();

export const DataProvider = (props) => {
  const [includeManufacturingGhg, setIncludeManufacturingGhg] = useState(false);
  const [sliderConfig, setSliderConfig] = useState(InitialSliderConfigData);
  const [sliderPresets, setSliderPresets] = useState(InitialSliderPresets);

  const [datasetGeneralSettings, setDatasetGeneralSettings] = useState(
    InitialGeneralSettings
  );
  const [chartData, setChartData] = useState(InitialDataset);

  const onIncludeManufacturingGhgChanged = () => {
    const newValue = !includeManufacturingGhg;
    setIncludeManufacturingGhg(newValue);
  };

  const selectDataset = (datasetIndex) => {
    // Ensure the selected dataset is in range
    datasetIndex =
      datasetIndex > DatasetConfig.length || datasetIndex < 0
        ? 0
        : datasetIndex;

    switch (datasetIndex) {
      case 0:
        setChartData(InitialDataset);
        setDatasetGeneralSettings(InitialGeneralSettings);
        setSliderConfig(InitialSliderConfigData);
        setSliderPresets(InitialSliderPresets);
        break;
      case 1:
        setChartData(_.cloneDeep(InitialDataset));
        setDatasetGeneralSettings(SecondaryGeneralSettings);
        setSliderConfig(SecondarySliderConfigData);
        setSliderPresets(SecondarySliderPresets);
        break;
      case 2:
        setChartData(Dataset3);
        setDatasetGeneralSettings(Dataset3GeneralSettings);
        setSliderConfig(Dataset3SliderConfigData);
        setSliderPresets(Dataset3SliderPresets);
        break;
      default:
    }

    // Disable mf ghg when dataset changes
    if (includeManufacturingGhg) {
      onIncludeManufacturingGhgChanged();
    }
  };

  return (
    <DataContext.Provider
      value={{
        includeManufacturingGhg: includeManufacturingGhg,
        onIncludeManufacturingGhgChanged: onIncludeManufacturingGhgChanged,
        datasetGeneralSettings: datasetGeneralSettings,
        chartData: chartData,
        selectChartDataset: selectDataset,
        sliderConfig: sliderConfig,
        sliderPresets: sliderPresets,
        datasetConfig: DatasetConfig,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
