import React, { useState, useEffect } from "react";
import Slider from "@bit/mui-org.material-ui.slider";

const VariableSlider = ({
  title,
  slider,
  sliderKey,
  minValue,
  maxValue,
  commitSlider,
  marksEnabled,
  stepValue,
}) => {
  const [sliderValue, setSliderValue] = useState(slider[sliderKey]);
  const [inputValue, setInputValue] = useState(slider[sliderKey]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setInputValue(newValue);
  };

  const handleSliderCommit = (event, newValue) => {
    commitSlider(sliderKey, newValue);
  };

  const handleInputBlur = () => {
    var newValue = inputValue;
    if (inputValue > maxValue) {
      newValue = maxValue;
    } else if (inputValue < minValue || inputValue === "") {
      newValue = minValue;
    }

    setInputValue(newValue);

    if (newValue >= minValue && newValue <= maxValue) {
      commitSlider(sliderKey, newValue);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.keyCode === 13) {
      document.getElementById(sliderKey).blur();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  useEffect(() => {
    if (slider[sliderKey] !== sliderValue) {
      setSliderValue(slider[sliderKey]);
      setInputValue(slider[sliderKey]);
    }
  }, [slider]);

  return (
    <div className="SliderWrapper">
      <p className="SliderText">{title}</p>
      <div className="Flex">
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommit}
          step={stepValue}
          marks={marksEnabled}
          min={minValue}
          max={maxValue}
        ></Slider>
        <input
          id={sliderKey}
          className="SliderInput"
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyPress}
        />
      </div>
    </div>
  );
};

export default VariableSlider;
