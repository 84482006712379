import React from "react";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <div className="Navbar">
      <a className="NavbarText" href="https://bit.ly/carghg_summary">
        CarGHG Summary
      </a>
      
      <a className="NavbarText" href="https://www.carghg.org">
        CarGHG Home
      </a>

      <Link to="/" className="NavbarText">
        WebApp: Cost vs GHG
      </Link>
      
       <Link to="/cost-module" className="NavbarText">
        WebApp: Total Cost of Ownership
      </Link>

      <Link to="/about" className="NavbarText">
        Support and FAQ
      </Link>
    </div>
  );
}

export default NavBar;
