import React from "react";
import Checkbox from "@bit/mui-org.material-ui.checkbox";
import Button from "@bit/mui-org.material-ui.button";

const VisualizationControls = ({
  onResetClick,
  manufacturerGhgIncluded,
  onManufacturerGhgChange,
  onDisclaimerClicked,
}) => {
  return (
    <div className="ButtonContainer">
      <Button onClick={onResetClick} variant="contained" size="small">
        Reset Data
      </Button>
      <div>
        <Checkbox
          color="primary"
          checked={manufacturerGhgIncluded}
          onChange={onManufacturerGhgChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <span onClick={onDisclaimerClicked} className="ClickableText">
          Manufacturing GHG Included*
        </span>
      </div>
    </div>
  );
};

export default VisualizationControls;
