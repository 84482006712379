import "./App.css";
import NavBar from "./NavBar";
import RouteSwitch from "./RouteSwitch";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <div className="Container">
          <RouteSwitch />
        </div>
      </Router>
    </div>
  );
}

export default App;
