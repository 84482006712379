import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../DataProvider";
import ScatterChart from "../ScatterChart/ScatterChart";
import CostModule from "../CostModule/CostModule";
import VisualizationSliders from "./VisualizationSliders";
import VisualizationDropdowns from "./VisualizationDropdowns";
import VisualizationControls from "./VisualizationControls";
import DisclaimerDialog from "./DisclaimerDialog";
import Credits from "./Credits";
import _ from "lodash";
import { VisualizationType } from "../Utils";

function generateInitialSliderValues(sliderConfig, sliderPresets, curPreset) {
  const initialSliderValues = {};
  if (curPreset === 0) {
    for (let key in sliderConfig) {
      if (sliderConfig.hasOwnProperty(key)) {
        initialSliderValues[key] = sliderConfig[key]["defaultValue"];
      }
    }
  } else {
    const index = curPreset - 1;
    const preset = sliderPresets[index]["valuesForSliderBars"];
    for (let key in preset) {
      if (preset.hasOwnProperty(key)) {
        initialSliderValues[key] = preset[key];
      }
    }
  }

  return initialSliderValues;
}

const VisualizationPage = ({ visualizationType }) => {
  const {
    includeManufacturingGhg,
    onIncludeManufacturingGhgChanged,
    sliderConfig,
    sliderPresets,
    selectChartDataset,
    datasetConfig,
    datasetGeneralSettings,
  } = useContext(DataContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [mfGhgChanged, setMfGhgChanged] = useState(false);

  const openDialog = () => {
    if (!mfGhgChanged) {
      setMfGhgChanged(true);
    }

    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const mfGhgCheckboxChange = () => {
    if (!mfGhgChanged) {
      openDialog();
    }

    onIncludeManufacturingGhgChanged();
  };

  const [sliderValues, setSliderValues] = useState(
    generateInitialSliderValues(sliderConfig, sliderPresets, 0)
  );

  const [currentPreset, setCurrentPreset] = useState(0);
  const [currentChartDataset, setCurrentChartDataset] = useState(0);

  const onSliderCommit = (key, newValue) => {
    const newValues = _.cloneDeep(sliderValues);
    newValues[key] = newValue;
    setSliderValues(newValues);
  };

  const onResetClick = () => {
    setSliderValues(
      generateInitialSliderValues(sliderConfig, sliderPresets, currentPreset)
    );
  };

  const onPresetChange = (event) => {
    const value = event.target.value;

    setSliderValues(
      generateInitialSliderValues(sliderConfig, sliderPresets, value)
    );

    setCurrentPreset(event.target.value);
  };

  const onChartDatasetChange = (event) => {
    setCurrentChartDataset(event.target.value);
    selectChartDataset(event.target.value);

    // Ensure preset is within range:
    setCurrentPreset(0);
  };

  const generateTextUnderGraph = (rawText) => {
    if (rawText === "") {
      let chartInstructionText =
        visualizationType === VisualizationType.ScatterPlot
          ? "Note: Hover over the points for more information."
          : "Note: Hover over the bars for more information.";
      return <p className="ChartInstructionText">{chartInstructionText}</p>;
    }

    const linkStartPos = rawText.search("link=");
    if (linkStartPos === -1) {
      return <p className="ChartInstructionText">{rawText}</p>;
    }

    const linkEndPos = rawText.search("=link");
    if (linkEndPos === -1) {
      return <p className="ChartInstructionText">{rawText}</p>;
    }

    const first = rawText.substring(0, linkStartPos);
    const second = rawText.substring(linkEndPos + 5);

    const url = rawText.substring(linkStartPos + 5, linkEndPos);

    return (
      <p className="ChartInstructionText">
        {first}
        <a href={url} target="_blank">
          {url}
        </a>
        {second}
      </p>
    );
  };

  useEffect(() => {
    setSliderValues(
      generateInitialSliderValues(sliderConfig, sliderPresets, currentPreset)
    );
  }, [sliderConfig]);

  return (
    <div className="VisualizationContainer">
      <DisclaimerDialog open={dialogOpen} handleClose={closeDialog} />

      <div className="ChartInstructionContainer">
        {visualizationType === VisualizationType.ScatterPlot ? (
          <ScatterChart sliderBarValues={sliderValues} />
        ) : (
          <CostModule sliderBarValues={sliderValues} />
        )}

        <p className="ChartInstructionText">
          {generateTextUnderGraph(
            datasetGeneralSettings.hasOwnProperty("textUnderGraph")
              ? datasetGeneralSettings["textUnderGraph"]
              : ""
          )}
        </p>
      </div>

      <div className="SideBarContainer">
        <VisualizationDropdowns
          currentDataset={currentChartDataset}
          onDatasetChange={onChartDatasetChange}
          currentPreset={currentPreset}
          onPresetChange={onPresetChange}
          presetNames={["Default Scenario"].concat(
            sliderPresets.map((preset) => preset["captionForDropDownList"])
          )}
          datasetNames={datasetConfig.map((dataset) => {
            if (!dataset["enabled"]) return null;
            return dataset["dropdownName"];
          })}
        />
        <VisualizationControls
          onResetClick={onResetClick}
          manufacturerGhgIncluded={includeManufacturingGhg}
          onManufacturerGhgChange={mfGhgCheckboxChange}
          onDisclaimerClicked={openDialog}
        />
        <VisualizationSliders
          sliderValues={sliderValues}
          commitSlider={onSliderCommit}
        />
        <Credits />
      </div>
    </div>
  );
};

export default VisualizationPage;
