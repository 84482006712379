import React from "react";
import Button from "@bit/mui-org.material-ui.button";



function About() {
  return (
    <div className="AboutContainer">
     
      <p><h3>CarGHG WebApp current version is 0.8.3</h3></p>
      <h2>CarGHG WebApp Quickstart</h2>
      <img src="https://www.carghg.org/images/app_quickstart.jpg" className="responsive"> 

        </img>
      <h2>Slider Definitions</h2>
      <p><strong><em>Reference Battery System Cost [$/kWh]</em></strong> A change in the unit cost of the battery pack changes the cost to build a vehicle, and thus the owner's TCO.
      This slider shows the
      sensitivity of each vehicle’s TCO (total cost of ownership) to changes in
      battery pack costs.&nbsp; </p>
      <p><strong><em>Reference Motor System Cost [$/kW]</em></strong> A change in the unit cost of a motor changes the cost to build a vehicle, and thus the owner's TCO.
      This slider shows the sensitivity of each vehicle’s TCO to changes in motor costs.&nbsp; </p>
    <p><strong><em>Fraction of Maximum Incentives [%]</em></strong> <em>CarGHG</em>&nbsp;
      estimates the financial value of all federal and state incentives for
      vehicles operating in 2021 in California, USA.&nbsp; These include non-financial
      incentives, such as access to High Occupancy Vehicle (HOV). The value of
      incentives assigned to any specific vehicle are detailed in the original
      &nbsp;<em>CarGHG</em>&nbsp; tool.&nbsp; A value of 0% removes all incentives from TCO
      consideration.&nbsp; </p>
    <p><strong><em>Electricity Price [Cents/kWh]</em></strong> shows the
      sensitivity of each vehicle’s TCO to changes in residential electricity
      costs.&nbsp; As a simplifying assumption, all vehicle charging is assumed
      to be done at home, at this (constant) rate.&nbsp;&nbsp; More complex
      pricing has been considered but will likely come first to the original
      &nbsp;<em>CarGHG</em>&nbsp;.</p>
    <p><em><strong>Gasoline Price [$/gal] </strong></em>shows the sensitivity
      of each vehicle’s TCO to changes in gasoline prices.&nbsp; As
      a simplifying assumption, it is assumed that this price remains constant
      for all of the years of vehicle ownership.</p>
    <p><em><strong>Well-to-Battery Electricity GHG [g-CO2/kWh]</strong></em>
    &nbsp;shows the sensitivity of each vehicle’s well-to-wheels GHG to changes in
      the well-to-wheels GHG of the electricity it uses.&nbsp; Any efficiency
      losses from battery to wheel are already modeled in each vehicle model.</p>
      <p><em><strong>Minimum Time Window for Daytime Charging [hr]</strong></em>
      &nbsp;defines the charging behavior assumed.&nbsp; Today, both charging
      behavior and the availability of charging infrastructure are uncertain, 
      so we developed a model to consider the impacts of daytime
      charging on the expected GHG produced in any given trip or
      travel-day.&nbsp; A description of this model of charging events can be found in
      Section 2.2 of <em>Optimizing the electric range of plug-in vehicles via
        fuel economy simulations of real-world driving in California</em> [<a href="https://doi.org/10.1016/j.trd.2019.05.013">https://doi.org/10.1016/j.trd.2019.05.013</a>]<br />
        Essentially, we assume that any time the vehicle is not driven for the
      specified time window or longer, we assume the vehicle is charging, at Level 2 for
      PHEVs, and at Level 3 for BEVs.&nbsp; <br />
      We tend to focus on these three choices: &#10100;12 hrs, 4 hrs, 0.5 hrs&#10101;.&nbsp;
      Setting this slider at 12 hours roughly represents overnight charging
      only.&nbsp; Choosing 4 hours represents primarily charging at work/school,
      plus overnight charging.&nbsp; Choosing 0.5 hours represents the idealized
      “chargers everywhere” scenario.<br />
      <em>Note 1</em>: The main <em>CarGHG</em>&nbsp; tool includes additional modeling
      options for charging behavior, including <em>Fraction of PHEVs not charging.</em><br />
      <em>Note 2</em>: We are very open to user’s suggestions on how best to
      model the current charging behavior, especially as clearer data becomes
      available.</p>
    <p><em><strong>Number of Years of Vehicle Ownership [year]</strong></em>
    &nbsp;Vehicles are modeled to be brand new at the start of the analysis period,
      and Number of years Ownership is used to estimate the depreciation of the
      vehicle at the end of the analysis period.&nbsp; <br />
      <em>Note 1</em>: Manufacturing GHG is not a function of the Number of
      Years of Vehicle Ownership, as the per-mile manufacturing GHG is spread
      over the whole life of the car, regardless of the length of ownership of
      the first user.&nbsp; <br />
      <em>Note 2</em>: No major expense, e.g. the replacement of the vehicles
      engine or traction battery, is included in our TCO calculation.&nbsp;
      Instead, a fixed annual maintenance cost is included in the TCO.&nbsp; In
      the future, with clearer data, this may change.</p>

      <h2>FAQ</h2>
      <p><u>Q: Where can I find further documentation?</u><br />
      A: The above information in the Quick Start and Slider Definitions is
      currently the primary documentation for the web app <em>CarGHG WebApp</em>.&nbsp;
      <em>CarGHG WebApp</em> is primarily intended to give users a high-level
      appreciation of what <em>CarGHG</em> can do, without requiring download
      or installation.<u>
      </u></p>
      <p><u>Q: OK, so how can I get documentation for CarGHG?</u><br />
      The<em> CarGHG</em> download includes a brief User Guide, which is also
      separately accessible from www.carghg.org. In addition, <em>CarGHG</em>
      is open source, with all code available for public review and commentary.
      That said, we know that <em>CarGHG</em> would greatly benefit from a
      detailed user’s manual.&nbsp; While this remains high on our list for
      future work, in the interim, please ask us detailed questions.&nbsp; The
      best way to contact us is to email info@carghg.org.&nbsp; We intend to
      answer all queries quickly.&nbsp; Depending on the complexity of the
      question and answer, we may suggest switching to a different communication
      method.&nbsp; We are also considering other channels of support.<br />&nbsp;
      In addition, many of the key features and methods of <em>CarGHG</em>
      are explained in one or more of our published papers. Below, you will find
      a list of references that explains how certain parts of <em>CarGHG</em>
      are modeled.&nbsp; We plan to continually add to this list.</p>
      <table>
        <thead>
          <tr>
            <th>Topic </th>
            <th> Reference </th>
          </tr>
        </thead>

        <tbody>
        <tr>
            <td>Overview of GHG Estimates </td>
            <td>Section 2.1 and Figure 1 of <em>Optimizing the electric range of plug-in vehicles via fuel economy simulations of real-world driving in California [<a href="https://doi.org/10.1016/j.trd.2019.05.013">https://doi.org/10.1016/j.trd.2019.05.013</a>] </em></td>
          </tr>

          <tr>
            <td>Charging Behavior </td>
            <td>Section 2.2 of <em>Optimizing the electric range of plug-in vehicles via fuel economy simulations of real-world driving in California [<a href="https://doi.org/10.1016/j.trd.2019.05.013">https://doi.org/10.1016/j.trd.2019.05.013</a>] </em></td>
          </tr>
          <tr>
            <td>Cost Modeling </td>
            <td><em>On Modeling the Cost of Ownership of Plug-In Vehicles [<a href="https://doi.org/10.3390/wevj12010039">https://doi.org/10.3390/wevj12010039</a>] </em></td>
          </tr>
          <tr>
            <td>Vehicle Model Validation </td>
            <td><em>Validity Assessment and Calibration Approach for Simulation Models of Energy Efficiency of Light-Duty Vehicles [<a href="https://doi.org/10.4271/2020-01-1441">https://doi.org/10.4271/2020-01-1441</a>] </em></td>
          </tr>
        </tbody>
      </table>
      <p><u>Q: What is the difference between <em>CarGHG</em> and <em>CarGHG WebApp</em>?</u><br />
      A: <em>CarGHG </em>is the original implementation of the
      current analysis tool.&nbsp; It requires downloading and installing the
      program from <em>www.carghg.org</em>. The functionality of <em>CarGHG
        WebApp</em> is the subset of two of the modules in <em>CarGHG</em>, with
      the <em>CarGHG </em>version having many more sliders and configurations
      available.&nbsp; In addition, <em>CarGHG </em>provides links that
      explain our validated vehicle models.&nbsp; <em>CarGHG </em>additionally
      has a module that shows full distribution curves of the GHG
      analysis.&nbsp; <em>CarGHG WebApp</em> is a web application that allows
      users to generally understand what <em>CarGHG </em>is, without needing
      to first download and install anything.</p>

        <p><u>Q: How can I be assured that these results are valid and accurate?</u><br />
        A: The web app, <em>CarGHG WebApp</em>, is designed to closely
      reflect the results of our primary analysis tool, <em>CarGHG</em>,
      downloadable at <em>www.carghg.org</em>. That tool is built from 
      open-source code, available from the associated Github site, and open to
      review by all.&nbsp; Further, each vehicle model based on an existing
      vehicle has gone through a validation process, as described in our papers
      (see above).&nbsp; Many of the other key steps of the tool’s analysis are
      described in public, peer-reviewed articles.&nbsp; We feel this represents
      a transparent, good-faith effort to predict how vehicles perform on the
      roads. Of course, we are fallible human beings.&nbsp; If you find
      something that looks wrong, please let us know.&nbsp; As was state
      elsewhere: <em>We make no guarantees about the tool, except that we very 
      much want to fix any issues or bugs you may find.</em></p>
    
    <p><u>Q: Why do I see differences between the web app <em>CarGHG WebApp</em> and the <em>CarGHG</em> program?</u><br />
        A: The original, downloadable application <em>CarGHG</em> remains the primary
      analysis tool.&nbsp; It has more features and flexibility, including the
      ability to save, recreate, and export results. Users can also use the
      original CarGHG to create their own analysis (e.g. analysis with their own
      trip files).&nbsp; There may be times when the downloadable application
      has recent code updates that have not yet been ported to the web
      app.&nbsp; That said, it is our goal to keep the web app <em>CarGHG WebApp</em> in
      sync with the primary <em>CarGHG</em> as much as possible.</p>

      <p><u>Q: What is the difference between <em>CarGHG WebApp</em> and <em>CarbonCounter</em></u><br />
      A: Functionally, both of these web apps have many
      similarities.&nbsp; <br />
      We are fans of <em>CarbonCounter</em> and its creators, the MIT Trancik
      Lab.&nbsp; It has been around as a web application for longer than <em>CarGHG
        WebApp</em>&nbsp;and has developed a great interface.&nbsp; 
        <br />To the best of our recollection, the <em>CarGHG</em>&nbsp;team had already sketched
      out the basic contours of <em>CarGHG</em>&nbsp;(including a planned web app)
      before discovering <em>CarbonCounter</em>. We are happy to learn that our
      two groups (apparently independently) share a similar vision of how to
      think about these topics.<br />
      We think the largest differences between the two tools are their
      respective sources of data.&nbsp; <em>CarbonCounter</em>&nbsp;uses the US EPA
      label values to calculate the use-phase GHG of each vehicle, while <em>CarGHG</em>
      &nbsp;uses its own simulation results of each vehicle performing real-world
      driving.&nbsp; This means that <em>CarGHG</em> can also calculate, and
      visualize, the performance of hypothetical vehicles.&nbsp; <br />
      Similarly, <em>CarbonCounter</em>&nbsp;uses the Manufacture Suggested Retail
      Price (MSRP) as an input into the Total Cost of Ownership (TCO) model. 
      &nbsp;<em>CarGHG</em>&nbsp;uses the MSRP for actual vehicles (which have official MSRPs) but can
      additionally use its own (published and open-source) cost model as an
      input to its TCO estimates.&nbsp; This allows <em>CarGHG</em> to estimate
      costs of imagined vehicles, which do not have a MSRP.&nbsp; In addition, 
      <em>CarGHG</em>&nbsp;can also predict the cost sensitivity of real vehicles if the costs of
      components change.&nbsp; For example, <em>CarGHG</em>&nbsp;can predict what a
      discontinued vehicle would now cost to build, assuming today’s lower
      battery costs, or even a future cost if batteries become cheaper still.<br />
      However, <em>CarbonCounter</em>&nbsp;models far more vehicles than <em>CarGHG</em>,
      as we have found the creation and validation of each <em>CarGHG</em>&nbsp;vehicle
      model to take a fair amount of effort.<br />
      Stated succinctly, <em>CarbonCounter</em>&nbsp;compares performances of many
      vehicles currently (or recently) available for purchase in the US (which
      all have an EPA rating and an MSRP). <em>CarGHG</em>&nbsp;can do the same, but
      can also include hypothetical vehicles in its evaluations.</p>

      <p><u>Q: Who are the people that produced this tool?</u><br />
      A: The web application (WebApp) was built by Nicholas Makharinets.&nbsp;Nicholas
      worked closely with the architect and creator of <em>CarGHG</em>,&nbsp;Karim
      Hamza.&nbsp; Ken Laberteaux and Jean Chu provided additional
      support.&nbsp; This work was done while Karim, Ken, and Jean worked at
      Toyota Motor North America-R&amp;D in Ann Arbor, MI.</p>
    <p><u>Q: Can we site the results of <em>CarGHG WebApp</em> in our papers,
        presentations, and reports?</u><br />
     A: Absolutely!&nbsp; When doing so, we suggest the following citation:<br />
     <em>Open-source tool for estimation of cost and greenhouse gas emissions of
      light-duty vehicles: CarGHG WebApp, v X.XX (webapp.carghg.org)</em> <br />
      where X.XX denotes the version of <em>CarGHG WebApp</em>&nbsp;used, shown at the
      top of this page.</p>

      <h2>ChangeLog</h2>
      <p><strong>0.8.3</strong>&nbsp;Fix for the bug where cost bars did not multiply certain annual costs insurance by the number of years of vehicle ownership (20 Nov 2023)</p>
      <p><strong>0.8.2</strong>&nbsp;Set default incentive=0 until new incentive structure is coded (01 Feb 2023)</p>
      <p><strong>0.8.1</strong>&nbsp;Minor Fixes (01 Feb 2023)</p>
      <p><strong>0.8.0</strong>&nbsp;Added Total Cost of Ownership module to Web App (01 Feb 2023)</p>
      <p><strong>0.7.1</strong>&nbsp;Added About link and page, providing a Quick Start, Slider Definitions, and FAQ (07 Jun 2022)</p>
      <p><strong>0.7.0</strong>&nbsp;Public beta release at app.carghg.org (17 Apr 2022)</p>
      <p><strong>0.6.0</strong>&nbsp;Soft launch of beta (23 Feb 2022)</p>
    </div>
  );
}

export default About;
