import React, { useContext } from "react";
import VariableSlider from "./VariableSlider";
import { DataContext } from "../DataProvider";

const VisualizationSliders = ({ sliderValues, commitSlider }) => {
  const { sliderConfig } = useContext(DataContext);

  return (
    <div className="SliderContainer">
      {Object.keys(sliderConfig).map((key) => {
        if (sliderConfig[key]["enabled"]) {
          return (
            <VariableSlider
              title={sliderConfig[key]["caption"]}
              slider={sliderValues}
              sliderKey={key}
              minValue={sliderConfig[key]["minValue"]}
              maxValue={sliderConfig[key]["maxValue"]}
              commitSlider={commitSlider}
              key={key}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default VisualizationSliders;
