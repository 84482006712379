import React from "react";

const Credits = () => {
  return (
    <p className="CreditsText">
      Desktop version of this tool was primarily created by Karim Hamza, a
      researcher of Toyota Motor North America R&D, with web-app development
      primarily done by Nicholas Makharinets. Both desktop and web-app are
      shared in the spirit of researcher-to-researcher information sharing,
      peer-review, and collaboration. We make no guarantees about the tool,
      except that we very much want to fix any issues or bugs you may find.
      Current version, changeLog, and a quick HowTo are available at the
      'Support and FAQ' link above.
      Please direct comments and questions to{" "}
      <a href="mailto: info@carghg.org">info@carghg.org</a>
    </p>
  );
};

export default Credits;
