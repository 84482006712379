import {
  PowerTrainTypes,
  FuelTypes,
  calculatePowertrainElementCost,
  calculateBatteryCost,
  calculateResaleValue,
  getAdjustedSliderValues,
} from "./CalcUtil";

export function calculateXY(
  vehicle,
  sliderBarValues,
  sliderBarConfig,
  genSettings,
  includeMfgGHG
) {
  const adjustedSliderValues = getAdjustedSliderValues(
    sliderBarValues,
    sliderBarConfig
  );
  switch (vehicle.powerTrainType) {
    case PowerTrainTypes.ICE:
      return calculateXY_ice(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.HEV:
      return calculateXY_hev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.PHEV:
      return calculateXY_phev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.BEV:
      return calculateXY_bev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.COMMERCIAL_BEV:
      return calculateXY_bevCommercial(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.FCEV:
      return calculateXY_fcev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.FCPHEV:
      return calculateXY_fcphev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    default:
      return { x: 0, y: 0 };
  }
}

// Function for Calculating XY Graph values for Conventional Internal Combustion Engine Vehicles
function calculateXY_ice(vehicle, sliderBarValues, genSettings, includeMfgGHG) {
  // Calculate engine cost & cost and GHG per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;
  let gCO2perUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
    gCO2perUnitFuel = sliderBarValues.ghg_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
    gCO2perUnitFuel = sliderBarValues.ghg_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
    gCO2perUnitFuel = sliderBarValues.ghg_ng;
  }

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe; // Retail price equivalent
  const purchaseCost = rpe * vehicle.dollarsBaseVehicle + rpe * engineCost;
  // RPE * DOLLARS BASE VEHICLE = GREY
  // RPE * powertrain on chart

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Fuel Amount per Mile
  const fuelAmountPerMile = vehicle.baselineFuelPerMile;

  // Well-to-Wheels GHG per mile
  const ghgPerMile_wtw = gCO2perUnitFuel * fuelAmountPerMile;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Total annual running cost
  const annualRunningCost =
    annualFuelCost +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Total Cost of ownership and cost per mile
  const tco = netAcquisitionCost + annualRunningCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  //Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG = gCO2perKgVehicle * vehicle.vehWeightExceptBattery;
  }

  //Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

// Function for Calculating XY Graph values for Hybrid-Electric Vehicles (non Plug-in)
function calculateXY_hev(vehicle, sliderBarValues, genSettings, includeMfgGHG) {
  // Calculate engine cost & cost and GHG per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;
  let gCO2perUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
    gCO2perUnitFuel = sliderBarValues.ghg_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
    gCO2perUnitFuel = sliderBarValues.ghg_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
    gCO2perUnitFuel = sliderBarValues.ghg_ng;
  }

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpe * engineCost +
    rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings,
    includeMfgGHG
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Fuel Amount per Mile
  const fuelAmountPerMile = vehicle.baselineFuelPerMile;

  //Well-to-Wheels GHG per mile
  const ghgPerMile_wtw = gCO2perUnitFuel * fuelAmountPerMile;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Total annual running cost
  const annualRunningCost =
    annualFuelCost +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Total Cost of ownership and cost per mile
  const tco = netAcquisitionCost + annualRunningCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

// Function for Calculating XY Graph values for Plug-in Hybrid-Electric Vehicles
function calculateXY_phev(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate engine cost & cost and GHG per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;
  let gCO2perUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
    gCO2perUnitFuel = sliderBarValues.ghg_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
    gCO2perUnitFuel = sliderBarValues.ghg_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
    gCO2perUnitFuel = sliderBarValues.ghg_ng;
  }

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpe * engineCost +
    rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Adding a home charger setup cost
  const homeChargerCost =
    sliderBarValues.fracCostHomeCharger *
    genSettings.homeChargerAddCost.forPHEVs_L1;

  // Use-phase Fuel and Electricity
  const nonChargersFuelAmountPerMile = vehicle.baselineFuelPerMile;
  const frac_ownersWhoDontCharge = sliderBarValues.bhv_phevsNotCharging;
  const frac_ownersWhoCharge = 1.0 - frac_ownersWhoDontCharge;

  const minDayTimeChargingWindow = sliderBarValues.bhv_minChgDayTimeWindow;
  const frac_overnightOnlyCharging =
    (minDayTimeChargingWindow -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg) /
    (genSettings.presetSliderLimits_dayTimeMinHours.overnightOnly -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg);
  const frac_maxDaytimeCharging = 1.0 - frac_overnightOnlyCharging;

  const frac_optTimingForMinGHG = sliderBarValues.bhv_chgEventTiming;
  const frac_optTimingForMinCost = 1.0 - frac_optTimingForMinGHG;

  const chargers_fuelAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.fuelPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.fuelPerMile;
  const chargers_electAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.kWhPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.kWhPerMile;

  const fuelAmountPerMile =
    frac_ownersWhoDontCharge * nonChargersFuelAmountPerMile +
    frac_ownersWhoCharge * chargers_fuelAmoutPerMile;
  const eclectricityAmountPerMile =
    frac_ownersWhoCharge * chargers_electAmoutPerMile;

  // Electricity Cost and GHG
  const elect_unitGHGModifier =
    frac_overnightOnlyCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.overnightOnly.optCostTiming.electGHGMod +
    frac_overnightOnlyCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.overnightOnly.optGHGTiming.electGHGMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.maxDayTime.optCostTiming.electGHGMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.maxDayTime.optGHGTiming.electGHGMod;

  const elect_unitCostModifier =
    frac_overnightOnlyCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.overnightOnly.optCostTiming.electCostMod +
    frac_overnightOnlyCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.overnightOnly.optGHGTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.maxDayTime.optCostTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.maxDayTime.optGHGTiming.electCostMod;

  const dollarPerUnitElectricity =
    elect_unitCostModifier * sliderBarValues.fuelPrice_elect;
  const gCO2perUnitElectricity =
    elect_unitGHGModifier * sliderBarValues.ghg_elect;

  // Well-to-Wheels GHG per mile
  const ghgPerMile_wtw =
    gCO2perUnitFuel * fuelAmountPerMile +
    (gCO2perUnitElectricity * eclectricityAmountPerMile) / vehicle.chgEff;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Annual Cost of electricity
  const annualElectricityCost =
    (yearMiles * eclectricityAmountPerMile * dollarPerUnitElectricity) /
    vehicle.chgEff;

  // Total annual running cost
  const annualRunningCost =
    annualFuelCost +
    annualElectricityCost +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Total Cost of ownership and cost per mile
  const tco =
    netAcquisitionCost + homeChargerCost + annualRunningCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

// Function for Calculating XY Graph values for Battery (only) Electric Vehicles
function calculateXY_bev(vehicle, sliderBarValues, genSettings, includeMfgGHG) {
  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle + rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings,
    includeMfgGHG
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Adding a home charger setup cost
  const homeChargerCost =
    sliderBarValues.fracCostHomeCharger *
    genSettings.homeChargerAddCost.forBEVs_L2;

  // User-behavior-dependancies
  const minDayTimeChargingWindow = sliderBarValues.bhv_minChgDayTimeWindow;
  const frac_overnightOnlyCharging =
    (minDayTimeChargingWindow -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg) /
    (genSettings.presetSliderLimits_dayTimeMinHours.overnightOnly -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg);
  const frac_maxDaytimeCharging = 1.0 - frac_overnightOnlyCharging;

  const rangeAnxMiles = sliderBarValues.bhv_BEVRangeAnx;
  const frac_maxRangeAnxBehavior =
    (rangeAnxMiles - genSettings.presetSliderLimits_bevRangeAnx.minAnxMiles) /
    (genSettings.presetSliderLimits_bevRangeAnx.maxAnxMiles -
      genSettings.presetSliderLimits_bevRangeAnx.minAnxMiles);
  const frac_minRangeAnxBehavior = 1.0 - frac_maxRangeAnxBehavior;

  const frac_optTimingForMinGHG = sliderBarValues.bhv_chgEventTiming;
  const frac_optTimingForMinCost = 1.0 - frac_optTimingForMinGHG;

  const frac_bevRep1 = 1.0 - sliderBarValues.bhv_BEVRepVehID;
  const frac_bevRep2 = 1.0 - frac_bevRep1;

  // Four corners interpolation -> nearest 4 sim cases for the inputs
  // get fraction of miles on bev (some wont be satisfied by bev)
  // fraction of days on bev,
  const w11 = frac_overnightOnlyCharging * frac_minRangeAnxBehavior;
  const w12 = frac_overnightOnlyCharging * frac_maxRangeAnxBehavior;
  const w21 = frac_maxDaytimeCharging * frac_minRangeAnxBehavior;
  const w22 = frac_maxDaytimeCharging * frac_maxRangeAnxBehavior;

  const fracMilesOnBEV =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.fracMilesOnBEV +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.fracMilesOnBEV +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.fracMilesOnBEV +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.fracMilesOnBEV;

  const fracDaysOnBEV =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.fracDaysOnBEV +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.fracDaysOnBEV +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.fracDaysOnBEV +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.fracDaysOnBEV;

  const avKWhperMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.avKWhperMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.avKWhperMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.avKWhperMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.avKWhperMile;

  const repVeh1_fuelPerMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.repVeh1_fuelPerMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.repVeh1_fuelPerMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.repVeh1_fuelPerMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.repVeh1_fuelPerMile;
  const repVeh2_fuelPerMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.repVeh2_fuelPerMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.repVeh2_fuelPerMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.repVeh2_fuelPerMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.repVeh2_fuelPerMile;

  const chgTiming_optCost_costMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optCostTiming.electCostMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optCostTiming.electCostMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optCostTiming.electCostMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optCostTiming.electCostMod;
  const chgTiming_optCost_ghgMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optCostTiming.electGHGMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optCostTiming.electGHGMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optCostTiming.electGHGMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optCostTiming.electGHGMod;

  const chgTiming_optGHG_costMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optGHGTiming.electCostMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optGHGTiming.electCostMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optGHGTiming.electCostMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optGHGTiming.electCostMod;
  const chgTiming_optGHG_ghgMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optGHGTiming.electGHGMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optGHGTiming.electGHGMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optGHGTiming.electGHGMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optGHGTiming.electGHGMod;

  // Use-phase costs and GHG
  const yearMiles = sliderBarValues.annualMiles;
  const yearMilesOnBEV = fracMilesOnBEV * yearMiles;
  const yearMilesOnRepVeh = (1.0 - fracMilesOnBEV) * yearMiles;

  const daysOnRepVeh = (1.0 - fracDaysOnBEV) * sliderBarValues.annualDriveDays;

  const annualFuelRepVeh1 =
    frac_bevRep1 * yearMilesOnRepVeh * repVeh1_fuelPerMile;
  const annualFuelRepVeh2 =
    frac_bevRep2 * yearMilesOnRepVeh * repVeh2_fuelPerMile;

  let annualGHG = 0.0;
  let annualCost = 0.0;

  if (vehicle.fuelTypeRepVeh1 === FuelTypes.GASOLINE) {
    annualCost += sliderBarValues.fuelPrice_gas * annualFuelRepVeh1;
    annualGHG += sliderBarValues.ghg_gas * annualFuelRepVeh1;
  } else if (vehicle.fuelTypeRepVeh1 === FuelTypes.DIESEL) {
    annualCost += sliderBarValues.fuelPrice_diesel * annualFuelRepVeh1;
    annualGHG += sliderBarValues.ghg_diesel * annualFuelRepVeh1;
  } else if (vehicle.fuelTypeRepVeh1 === FuelTypes.NATURAL_GAS) {
    annualCost += sliderBarValues.fuelPrice_ng * annualFuelRepVeh1;
    annualGHG += sliderBarValues.ghg_ng * annualFuelRepVeh1;
  }

  if (vehicle.fuelTypeRepVeh2 === FuelTypes.GASOLINE) {
    annualCost += sliderBarValues.fuelPrice_gas * annualFuelRepVeh2;
    annualGHG += sliderBarValues.ghg_gas * annualFuelRepVeh2;
  } else if (vehicle.fuelTypeRepVeh2 === FuelTypes.DIESEL) {
    annualCost += sliderBarValues.fuelPrice_diesel * annualFuelRepVeh2;
    annualGHG += sliderBarValues.ghg_diesel * annualFuelRepVeh2;
  } else if (vehicle.fuelTypeRepVeh2 === FuelTypes.NATURAL_GAS) {
    annualCost += sliderBarValues.fuelPrice_ng * annualFuelRepVeh2;
    annualGHG += sliderBarValues.ghg_ng * annualFuelRepVeh2;
  }

  const electCostMod =
    frac_optTimingForMinCost * chgTiming_optCost_costMod +
    frac_optTimingForMinGHG * chgTiming_optGHG_costMod;
  const electGHGMod =
    frac_optTimingForMinCost * chgTiming_optCost_ghgMod +
    frac_optTimingForMinGHG * chgTiming_optGHG_ghgMod;

  const annualKWhFromGrid = (avKWhperMile * yearMilesOnBEV) / vehicle.chgEff;
  annualGHG += annualKWhFromGrid * sliderBarValues.ghg_elect * electGHGMod;
  annualCost +=
    annualKWhFromGrid * sliderBarValues.fuelPrice_elect * electCostMod;

  // SUM below is rep veh cost
  annualCost +=
    genSettings.bevRepVehCost.dollarPerDay * daysOnRepVeh +
    genSettings.bevRepVehCost.dollarPerMile * yearMilesOnRepVeh;
  annualCost +=
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Well-to-Wheels GHG
  const ghgPerMile_wtw = annualGHG / yearMiles;

  // Total Cost of ownership and cost per mile
  const tco = netAcquisitionCost + homeChargerCost + annualCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

function calculateXY_bevCommercial(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle + rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Net Acquisition Cost per single vehicle
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Use-phase costs and GHG
  const yearMiles = sliderBarValues.annualMiles;
  const avKWhperMile = vehicle.baselineKWhPerMile;

  const annualKWhFromGrid = (avKWhperMile * yearMiles) / vehicle.chgEff;
  const annualGHG = annualKWhFromGrid * sliderBarValues.ghg_elect;
  const annualCost =
    annualKWhFromGrid * sliderBarValues.fuelPrice_elect +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Well-to-Wheels GHG
  const ghgPerMile_wtw = annualGHG / yearMiles;

  // Total Cost of ownership and cost per mile
  const fracAdditionalVehiclesInFleet = sliderBarValues.commercialBEV_fracExtra;
  const tco =
    netAcquisitionCost * (1.0 + fracAdditionalVehiclesInFleet) +
    annualCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

// Function for Calculating XY Graph values for (Hydrogen) Fuel-Cell Electric Vehicles
function calculateXY_fcev(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate Fuel Cell cost
  const sliders_FuelCellDollarPerKW = sliderBarValues.fuelCellCost;
  const fuelCellCost = calculatePowertrainElementCost(
    genSettings.fuelCellCostData,
    vehicle.fuelCellKW,
    sliders_FuelCellDollarPerKW
  );

  // Calculate Hydrogen Tank Cost
  const sliders_HydrogenTankDollarPerKg = sliderBarValues.h2TkCost;
  const tankCost = calculatePowertrainElementCost(
    genSettings.hydrogenTankCostData,
    vehicle.h2TankKg,
    sliders_HydrogenTankDollarPerKg
  );

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpeElect * (motorCost + batteryCost + fuelCellCost + tankCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Fuel Amount per Mile
  const fuelAmountPerMile = vehicle.baselineFuelPerMile;

  // Cost and GHG per unit of fuel
  const dollarPerUnitFuel = sliderBarValues.fuelPrice_h2;
  const gCO2perUnitFuel = sliderBarValues.ghg_h2;

  // Well-to-Wheels GHG per mile
  const ghgPerMile_wtw = gCO2perUnitFuel * fuelAmountPerMile;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Total annual running cost
  const annualRunningCost =
    annualFuelCost +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Total Cost of ownership and cost per mile
  const tco = netAcquisitionCost + annualRunningCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}

function calculateXY_fcphev(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate Fuel Cell cost
  const sliders_FuelCellDollarPerKW = sliderBarValues.fuelCellCost;
  const fuelCellCost = calculatePowertrainElementCost(
    genSettings.fuelCellCostData,
    vehicle.fuelCellKW,
    sliders_FuelCellDollarPerKW
  );

  // Calculate Hydrogen Tank Cost
  const sliders_HydrogenTankDollarPerKg = sliderBarValues.h2TkCost;
  const tankCost = calculatePowertrainElementCost(
    genSettings.hydrogenTankCostData,
    vehicle.h2TankKg,
    sliders_HydrogenTankDollarPerKg
  );

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpeElect * (motorCost + batteryCost + fuelCellCost + tankCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Net Acquisition Cost
  const netAcquisitionCost = purchaseCost - incentives - reSaleValue;

  // Adding a home charger setup cost
  const homeChargerCost =
    sliderBarValues.fracCostHomeCarger *
    genSettings.homeChargerAddCost.forPHEVs_L1;

  // Use-phase Fuel and Electricity
  const nonChargersFuelAmountPerMile = vehicle.baselineFuelPerMile;
  const frac_ownersWhoDontCharge = sliderBarValues.bhv_phevsNotCharging;
  const frac_ownersWhoCharge = 1.0 - frac_ownersWhoDontCharge;

  const minDayTimeChargingWindow = sliderBarValues.bhv_minChgDayTimeWindow;
  const frac_overnightOnlyCharging =
    (minDayTimeChargingWindow -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg) /
    (genSettings.presetSliderLimits_dayTimeMinHours.overnightOnly -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg);
  const frac_maxDaytimeCharging = 1.0 - frac_overnightOnlyCharging;

  const frac_optTimingForMinGHG = sliderBarValues.bhv_chgEventTiming;
  const frac_optTimingForMinCost = 1.0 - frac_optTimingForMinGHG;

  const chargers_fuelAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.fuelPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.fuelPerMile;
  const chargers_electAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.kWhPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.kWhPerMile;

  const fuelAmountPerMile =
    frac_ownersWhoDontCharge * nonChargersFuelAmountPerMile +
    frac_ownersWhoCharge * chargers_fuelAmoutPerMile;
  const eclectricityAmountPerMile =
    frac_ownersWhoCharge * chargers_electAmoutPerMile;

  // Electricity Cost and GHG
  const elect_unitGHGModifier =
    frac_overnightOnlyCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.overnightOnly.optCostTiming.electGHGMod +
    frac_overnightOnlyCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.overnightOnly.optGHGTiming.electGHGMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.maxDayTime.optCostTiming.electGHGMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.maxDayTime.optGHGTiming.electGHGMod;

  const elect_unitCostModifier =
    frac_overnightOnlyCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.overnightOnly.optCostTiming.electCostMod +
    frac_overnightOnlyCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.overnightOnly.optGHGTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.maxDayTime.optCostTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.maxDayTime.optGHGTiming.electCostMod;

  const dollarPerUnitElectricity =
    elect_unitCostModifier * sliderBarValues.fuelPrice_elect;
  const gCO2perUnitElectricity =
    elect_unitGHGModifier * sliderBarValues.ghg_elect;

  // Cost and GHG per unit of fuel
  const dollarPerUnitFuel = sliderBarValues.fuelPrice_h2;
  const gCO2perUnitFuel = sliderBarValues.ghg_h2;

  // Well-to-Wheels GHG per mile
  const ghgPerMile_wtw =
    gCO2perUnitFuel * fuelAmountPerMile +
    (gCO2perUnitElectricity * eclectricityAmountPerMile) / vehicle.chgEff;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Annual Cost of electricity
  const annualElectricityCost =
    (yearMiles * eclectricityAmountPerMile * dollarPerUnitElectricity) /
    vehicle.chgEff;

  // Total annual running cost
  const annualRunningCost =
    annualFuelCost +
    annualElectricityCost +
    vehicle.licensingDollarsPerYear +
    vehicle.insuranceDollarsPerYear +
    vehicle.maintenanceDollarPerMile * yearMiles;

  // Total Cost of ownership and cost per mile
  const tco =
    netAcquisitionCost + homeChargerCost + annualRunningCost * numYears;
  const costPerMile = tco / (yearMiles * numYears);

  // Manufacturing GHG
  let vehLifetimeMfgGHG = 0;
  if (includeMfgGHG) {
    const batteryMfgGHG =
      vehicle.batteryKWh *
      vehicle.batteryMfg_gCO2perKWh *
      sliderBarValues.mfgGHG_battery;

    const c2 = sliderBarValues.mfgGHG_exceptBattery;
    const c1 = 1.0 - c2;
    const gCO2perKgVehicle =
      c1 * vehicle.unitMfgGHG_lowEnd + c2 * vehicle.unitMfgGHG_highEnd;
    vehLifetimeMfgGHG =
      gCO2perKgVehicle * vehicle.vehWeightExceptBattery + batteryMfgGHG;
  }

  // Adjusted GHG value
  const ghgPerMile =
    ghgPerMile_wtw + vehLifetimeMfgGHG / genSettings.vehicleLifeTimeMiles;

  return {
    x:
      ghgPerMile *
      genSettings.xyOutputAdjustment.ghg_valueMultiplierToOutputUnits,
    y:
      costPerMile *
      genSettings.xyOutputAdjustment.cost_valueMultiplierToOutputUnits,
  };
}
