import React, { useRef, useEffect, useState, useContext } from "react";
import { DataContext } from "../DataProvider";
import { PowerTrainNames } from "../Utils/CalcUtil";
import { calculateXY } from "../Utils/ScatterCalcUtils";
import Chart from "chart.js/auto";

const type = "scatter";

const simpleShapes = ["cross", "crossRot", "dash", "line", "star"];

function updateDataset(
  chart,
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  chart.config.options.plugins.title.text = genSettings.chartTitle;

  chart.config.options.scales.xAxis.max =
    genSettings.scatterChartAxes.xAxis_maxValue;
  chart.config.options.scales.xAxis.min =
    genSettings.scatterChartAxes.xAxis_minValue;
  chart.config.options.scales.xAxis.title.text = includeMfGhg
    ? genSettings.scatterChartAxes.xAxis_caption_w_mfGGHG
    : genSettings.scatterChartAxes.xAxis_caption_wo_mfGGHG;

  chart.config.options.scales.yAxis.max =
    genSettings.scatterChartAxes.yAxis_maxValue;
  chart.config.options.scales.yAxis.min =
    genSettings.scatterChartAxes.yAxis_minValue;
  chart.config.options.scales.yAxis.title.text =
    genSettings.scatterChartAxes.yAxis_caption;

  chart.data = formatDataSet(
    data,
    sliderValues,
    sliderConfig,
    genSettings,
    includeMfGhg
  );

  chart.update();
}

function updateData(
  chart,
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  for (let i = 0; i < chart.data.datasets.length; i++) {
    const dataset = chart.data.datasets[i];
    for (let j = 0; j < dataset.labels.length; j++) {
      const vehicle = data[dataset.labels[j]];
      dataset.data[j] = calculateXY(
        vehicle,
        sliderValues,
        sliderConfig,
        genSettings,
        includeMfGhg
      );
    }
  }

  chart.config.options.scales.xAxis.title.text = includeMfGhg
    ? genSettings.scatterChartAxes.xAxis_caption_w_mfGGHG
    : genSettings.scatterChartAxes.xAxis_caption_wo_mfGGHG;

  chart.options.plugins.tooltip.callbacks = {
    title: function (tooltipItems) {
      const item = tooltipItems[0];
      const itemKey = item.dataset.labels[item.dataIndex];
      return data[itemKey]["caption"];
    },
    footer: function (tooltipItems) {
      const item = tooltipItems[0];
      const powerTrain =
        data[item.dataset.labels[item.dataIndex]]["powerTrainType"];
      return PowerTrainNames[powerTrain];
    },
  };
  chart.update();
}

function formatDataSet(
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  const datasets = {};

  if (!data || !sliderValues || !genSettings) {
    return datasets;
  }

  for (let key in data) {
    if (!data.hasOwnProperty(key)) continue;

    const vehicle = data[key];
    if (datasets.hasOwnProperty(vehicle["plotColor"] + vehicle["plotShape"])) {
      const dataset = datasets[vehicle["plotColor"]];
      dataset.labels.push(key);
      dataset.data.push(
        calculateXY(
          vehicle,
          sliderValues,
          sliderConfig,
          genSettings,
          includeMfGhg
        )
      );
    } else {
      const newDataset = {};
      newDataset["label"] = vehicle["caption"];
      newDataset["labels"] = [key];
      newDataset["data"] = [
        calculateXY(
          vehicle,
          sliderValues,
          sliderConfig,
          genSettings,
          includeMfGhg
        ),
      ];

      newDataset["pointStyle"] = vehicle.hasOwnProperty("plotShape")
        ? vehicle["plotShape"]
        : "circle";

      newDataset["pointRadius"] = vehicle["plotSize"];
      newDataset["pointBackgroundColor"] = vehicle["plotColor"];

      if (simpleShapes.includes(newDataset["pointStyle"])) {
        newDataset["pointBorderColor"] = vehicle["plotColor"];
        newDataset["pointBorderWidth"] = vehicle.hasOwnProperty(
          "plotBorderWidth"
        )
          ? vehicle["plotBorderWidth"]
          : 1;
      }

      datasets[vehicle["plotColor"] + newDataset["pointStyle"]] = newDataset;
    }
  }

  const chartData = { datasets: [] };

  for (let key in datasets) {
    if (!datasets.hasOwnProperty(key)) return;
    chartData.datasets.push(datasets[key]);
  }
  return chartData;
}

function ScatterChart({ sliderBarValues }) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const {
    includeManufacturingGhg,
    datasetGeneralSettings,
    chartData,
    sliderConfig,
  } = useContext(DataContext);

  const renderChart = () => {
    if (!chartContainer.current) return;

    const plugins = {
      title: {
        display: true,
        text: datasetGeneralSettings.chartTitle,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const item = tooltipItems[0];
            const itemKey = item.dataset.labels[item.dataIndex];
            return chartData[itemKey]["caption"];
          },
          footer: function (tooltipItems) {
            const item = tooltipItems[0];
            const powerTrain =
              chartData[item.dataset.labels[item.dataIndex]]["powerTrainType"];
            return PowerTrainNames[powerTrain];
          },
        },
      },
    };

    const scales = {
      yAxis: {
        title: {
          display: true,
          text: datasetGeneralSettings.scatterChartAxes.yAxis_caption,
          padding: {
            top: 2,
            bottom: 2,
          },
        },
        max: datasetGeneralSettings.scatterChartAxes.yAxis_maxValue,
        min: datasetGeneralSettings.scatterChartAxes.yAxis_minValue,
      },
      xAxis: {
        title: {
          display: true,
          text: datasetGeneralSettings.scatterChartAxes.xAxis_caption_wo_mfGGHG,
          padding: {
            top: 2,
            bottom: 2,
          },
        },
        max: datasetGeneralSettings.scatterChartAxes.xAxis_maxValue,
        min: datasetGeneralSettings.scatterChartAxes.xAxis_minValue,
      },
    };

    const chart = new Chart(chartContainer.current, {
      type: type,
      data: formatDataSet(
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      ),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: plugins,
        scales: scales,
        // onClick: (e) => {
        //   const points = chart.getElementsAtEventForMode(
        //     e,
        //     "nearest",
        //     { intersect: true },
        //     true
        //   );

        //   if (points.length) {
        //     const firstPoint = points[0];
        //     var value =
        //       chart.data.datasets[firstPoint.datasetIndex].data[
        //         firstPoint.index
        //       ];
        //   }
        // },
      },
    });
    setChartInstance(chart);
  };

  const destroyChart = () => {
    if (chartInstance) {
      chartInstance.destroy();
      setChartInstance(null);
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, []);

  useEffect(() => {
    if (chartInstance) {
      updateData(
        chartInstance,
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      );
    }
  }, [sliderBarValues]);

  useEffect(() => {
    if (chartInstance) {
      updateDataset(
        chartInstance,
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      );
    }
  }, [chartData]);

  useEffect(() => {
    if (chartInstance) {
      updateData(
        chartInstance,
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      );
    }
  }, [includeManufacturingGhg]);

  return (
    <div className="ChartContainer">
      <canvas ref={chartContainer} className="ChartCanvas"></canvas>
    </div>
  );
}

export default ScatterChart;
