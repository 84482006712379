import React from "react";
import Dialog from "@bit/mui-org.material-ui.dialog";
import DialogContent from "@bit/mui-org.material-ui.dialog-content";
import DialogContentText from "@bit/mui-org.material-ui.dialog-content-text";

const DisclaimerDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          These manufacturing GHG estimates are based on{" "}
          <a href="https://greet.es.anl.gov/" target="_blank">
            GREET 2020
          </a>
          , and are based on the specific vehicle’s characteristics, such as
          battery size and chemistry, vehicle class and weight, etc. However,
          the authors have not validated, nor suggest, that this estimate is
          correct for any specific vehicle or manufacturer.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DisclaimerDialog;
