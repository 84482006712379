import React, { useRef, useEffect, useState, useContext } from "react";
import Chart from "chart.js/auto";
import { calculateCostValues } from "../Utils/CostModuleCalcUtils";
import { DataContext } from "../DataProvider";

// Define stack constants
const STACK_0 = "Stack 0";
const STACK_1 = "Stack 1";

// Define titles, colors and stacks for each cost segment
// Stack 0 => Cost, Stack 1 => Rebate
const DATASET_INFO = [
  ["Vehicle Cost aside from Powertrain", "#595857", STACK_0],
  ["Engine Cost", "#5c2c03", STACK_0],
  ["Motor Cost", "#bf870d", STACK_0],
  ["Battery Cost", "#32a31c", STACK_0],
  ["Charger Cost", "#e8887b", STACK_0],
  ["Resale Price", "#a4d99a", STACK_1],
  ["Incentives", "#dfe3bf", STACK_1],
  ["Licensing Cost", "#de9bcf", STACK_0],
  ["Insurance Cost", "#9e2aad", STACK_0],
  ["Maintenance Cost", "#e62ea8", STACK_0],
  ["Fuel Cost", "#000000", STACK_0],
  ["Electricity Cost", "#fff703", STACK_0],
  ["Replacement Vehicle Cost", "#eb1d0e", STACK_0],
];

// Will be populated everytime the dataset is changed, represents the TCO for each vehicle
// Indexed in order of appearance in the chart
let TCO = [];

function updateDataset(
  chart,
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  // Adjust chart axis as necessary
  chart.config.options.scales.xAxis.max =
    genSettings.costModuleAxes.xAxis_maxValue;
  chart.config.options.scales.xAxis.min =
    genSettings.costModuleAxes.xAxis_minValue;

  chart.data = formatDataSet(
    data,
    sliderValues,
    sliderConfig,
    genSettings,
    includeMfGhg
  );

  // Re-define title tooltip because captured chart data variable has changed
  chart.options.plugins.tooltip.callbacks.title = function (tooltipItems) {
    // Title shows name of vehicle
    const item = tooltipItems[0];
    const itemKey = item.label;
    return data[itemKey]["caption"];
  };

  chart.update();
}

function updateData(
  chart,
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  // TODO: REPLACE ALL THE FOR IN WITH FOR OF
  for (const [index, label] of chart.data.labels.entries()) {
    const vehicle = data[label];
    const costData = calculateCostValues(
      vehicle,
      sliderValues,
      sliderConfig,
      genSettings,
      includeMfGhg
    );

    // Set the various costs and rebates accordingly, then these values
    // will be adjusted into ranges, such that they form a continuous stacked graph
    chart.data.datasets[0].data[index] = costData.retailCost;
    chart.data.datasets[1].data[index] = costData.engineCost;
    chart.data.datasets[2].data[index] = costData.motorCost;
    chart.data.datasets[3].data[index] = costData.batteryCost;
    chart.data.datasets[4].data[index] = costData.homeChargerCost;
    chart.data.datasets[5].data[index] = -1 * costData.reSaleValue;
    chart.data.datasets[6].data[index] = -1 * costData.incentives;
    chart.data.datasets[7].data[index] = costData.licensing;
    chart.data.datasets[8].data[index] = costData.insurance;
    chart.data.datasets[9].data[index] = costData.maintenance;
    chart.data.datasets[10].data[index] = costData.fuelCost;
    chart.data.datasets[11].data[index] = costData.electricityCost;
    chart.data.datasets[12].data[index] = costData.replacementVehicleCost;
  }

  chart.data.datasets = computeOffset(chart.data.datasets);

  chart.update();
}

// Given a dataset (costs and rebates), transforms the costs
// into a set of bars with defined start / end such that all the bars together
// conform to the desired format
function computeOffset(datasets) {
  // Reset existing TCO data before recomputation
  TCO = [];
  const dataLength = datasets[0].data.length;
  const dataSetsLength = datasets.length;

  for (let i = 0; i < dataLength; i++) {
    let curTCO = 0;
    // Set Rebates
    let curStart = datasets[5].data[i] + datasets[6].data[i];
    // Subtract rebated from TCO
    curTCO += curStart;

    datasets[5].data[i] = [
      datasets[5].data[i] + datasets[6].data[i],
      datasets[6].data[i],
    ];
    datasets[6].data[i] = [datasets[6].data[i], 0];

    // Set costs
    for (let j = 0; j < dataSetsLength; j++) {
      if (datasets[j].stack === "Stack 1") {
        continue;
      }
      // Add costs to TCO
      curTCO += datasets[j].data[i];

      const newStart = curStart + datasets[j].data[i];
      datasets[j].data[i] = [curStart, newStart];
      curStart = newStart;
    }

    TCO.push(curTCO);
  }

  return datasets;
}

// Generate datasets from the current sliders and the current vehSummaries
// For the cost module, there is always a fixed number of datasets (the labels are the cars)
function formatDataSet(
  data,
  sliderValues,
  sliderConfig,
  genSettings,
  includeMfGhg
) {
  if (!data || !sliderValues || !genSettings) {
    return {};
  }

  let chartLabels = [];
  let datasets = [];

  // Create new datasets objects
  for (const [label, color, stack] of DATASET_INFO) {
    datasets.push({
      label: label,
      data: [],
      backgroundColor: color,
      stack: stack,
    });
  }

  for (let key in data) {
    if (!data.hasOwnProperty(key)) continue;

    chartLabels.push(key);
    const vehicle = data[key];

    const costData = calculateCostValues(
      vehicle,
      sliderValues,
      sliderConfig,
      genSettings,
      includeMfGhg
    );

    // Add all the relevant costs/rebates, which will then be adjusted to
    // create a series of stacked cost/rebate segments
    datasets[0].data.push(costData.retailCost);
    datasets[1].data.push(costData.engineCost);
    datasets[2].data.push(costData.motorCost);
    datasets[3].data.push(costData.batteryCost);
    datasets[4].data.push(costData.homeChargerCost);
    datasets[5].data.push(-1 * costData.reSaleValue);
    datasets[6].data.push(-1 * costData.incentives);
    datasets[7].data.push(costData.licensing);
    datasets[8].data.push(costData.insurance);
    datasets[9].data.push(costData.maintenance);
    datasets[10].data.push(costData.fuelCost);
    datasets[11].data.push(costData.electricityCost);
    datasets[12].data.push(costData.replacementVehicleCost);
  }

  const chartData = {
    labels: chartLabels,
    datasets: computeOffset(datasets),
  };

  return chartData;
}

function CostModule({ sliderBarValues }) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const {
    includeManufacturingGhg,
    datasetGeneralSettings,
    chartData,
    sliderConfig,
  } = useContext(DataContext);

  const renderChart = () => {
    if (!chartContainer.current) return;

    const plugins = {
      title: {
        display: true,
        text: "Total Cost of Ownership (TCO)",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            // Title shows name of vehicle
            const item = tooltipItems[0];
            const itemKey = item.label;
            return chartData[itemKey]["caption"];
          },
          label: function (tooltipItem) {
            // Label shows cost of specific segment
            // Format the bar magnitude as an amount in USD
            let label = tooltipItem.dataset.label || "";
            if (label) {
              label += ": ";
            }
            const valueString = Math.ceil(
              Math.abs(tooltipItem.raw[1] - tooltipItem.raw[0])
            ).toString();

            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(valueString);
            return label;
          },
          footer: function (tooltipItems) {
            // Footer shows TCO
            const item = tooltipItems[0];
            let label = "TCO: ";
            const valueString = Math.ceil(TCO[item.dataIndex]).toString();

            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(valueString);

            return label;
          },
        },
      },
    };

    const scales = {
      xAxis: {
        stacked: false,
        title: {
          display: true,
          text: "Cost [$]",
        },
        max: datasetGeneralSettings.costModuleAxes.xAxis_maxValue,
        min: datasetGeneralSettings.costModuleAxes.xAxis_minValue,
      },
      yAxis: {
        title: {
          display: true,
          text: "Vehicle Model",
        },
      },
    };

    const chart = new Chart(chartContainer.current, {
      type: "bar",
      data: formatDataSet(
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      ),
      options: {
        indexAxis: "y",
        plugins: plugins,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: true,
        },
        scales: scales,
      },
    });
    setChartInstance(chart);
  };

  const destroyChart = () => {
    if (chartInstance) {
      chartInstance.destroy();
      setChartInstance(null);
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, []);

  useEffect(() => {
    if (chartInstance) {
      updateData(
        chartInstance,
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      );
    }
  }, [sliderBarValues]);

  useEffect(() => {
    if (chartInstance) {
      updateDataset(
        chartInstance,
        chartData,
        sliderBarValues,
        sliderConfig,
        datasetGeneralSettings,
        includeManufacturingGhg
      );
    }
  }, [chartData]);

  return (
    <div className="ChartContainer">
      <canvas ref={chartContainer} className="ChartCanvas"></canvas>
    </div>
  );
}

export default CostModule;
