import _ from "lodash";

// TODO: Consider moving some of these to constants
export const PowerTrainNames = {
  ICE: "Conventional Internal Combustion Engine",
  HEV: "Hybrid Electric Vehicle",
  PHEV: "Plug-in Hybrid Electric Vehicle",
  BEV: "Battery (only) Electric Vehicle",
  FCEV: "Fuel-Cell Electric",
  FCPHEV: "Fuel-Cell Plug-in Hybrid Electric",
};

export const PowerTrainTypes = Object.freeze({
  ICE: "ICE",
  HEV: "HEV",
  PHEV: "PHEV",
  BEV: "BEV",
  COMMERCIAL_BEV: "COMMERCIAL_BEV",
  FCEV: "FCEV",
  FCPHEV: "FCPHEV",
});

export const FuelTypes = Object.freeze({
  GASOLINE: "Gasoline",
  DIESEL: "Diesel",
  NATURAL_GAS: "Natural Gas",
});

// Function for calculating the cost model value for a powertrain element (engine, motor or fuel cell, also for hydrogen tank)
export function calculatePowertrainElementCost(
  ptElemCostData,
  kW,
  sliderBarDollarPerKWValue
) {
  const unAdjustedSlope =
    (ptElemCostData.baselineKW * ptElemCostData.baselineDollarPerKW -
      ptElemCostData.dollarsAtZeroKW) /
    ptElemCostData.baselineKW;
  const unAdjustedCost = ptElemCostData.dollarsAtZeroKW + unAdjustedSlope * kW;
  const costScaling =
    sliderBarDollarPerKWValue / ptElemCostData.baselineDollarPerKW;
  return costScaling * unAdjustedCost;
}

// Function for calculating the cost model value for Batteries
export function calculateBatteryCost(
  batteryCostData,
  kWh,
  sliderBarDollarPerKWhValue,
  powertrainType
) {
  const baselineKWh = batteryCostData.baselineKWh;
  let unAdjustedCost = 0.0;

  if (powertrainType === PowerTrainTypes.PHEV) {
    const unAdjustedSlope =
      (baselineKWh * batteryCostData.phevBatteries.baselineDollarPerKWh -
        batteryCostData.phevBatteries.dollarsAtZeroKWh) /
      baselineKWh;
    unAdjustedCost =
      batteryCostData.phevBatteries.dollarsAtZeroKWh + unAdjustedSlope * kWh;
  } else if (powertrainType === PowerTrainTypes.BEV) {
    const unAdjustedSlope =
      (baselineKWh * batteryCostData.bevBatteries.baselineDollarPerKWh -
        batteryCostData.bevBatteries.dollarsAtZeroKWh) /
      baselineKWh;
    unAdjustedCost =
      batteryCostData.bevBatteries.dollarsAtZeroKWh + unAdjustedSlope * kWh;
  } else if (powertrainType === PowerTrainTypes.COMMERCIAL_BEV) {
    const unAdjustedSlope =
      (baselineKWh * batteryCostData.bevBatteries.baselineDollarPerKWh -
        batteryCostData.bevBatteries.dollarsAtZeroKWh) /
      baselineKWh;
    unAdjustedCost =
      batteryCostData.bevBatteries.dollarsAtZeroKWh + unAdjustedSlope * kWh;
  } else if (powertrainType === PowerTrainTypes.FCPHEV) {
    const unAdjustedSlope =
      (baselineKWh * batteryCostData.phevBatteries.baselineDollarPerKWh -
        batteryCostData.phevBatteries.dollarsAtZeroKWh) /
      baselineKWh;
    unAdjustedCost =
      batteryCostData.phevBatteries.dollarsAtZeroKWh + unAdjustedSlope * kWh;
  } else {
    // HEV and FCEV
    const unAdjustedSlope =
      (baselineKWh * batteryCostData.hevBatteries.baselineDollarPerKWh -
        batteryCostData.hevBatteries.dollarsAtZeroKWh) /
      baselineKWh;
    unAdjustedCost =
      batteryCostData.hevBatteries.dollarsAtZeroKWh + unAdjustedSlope * kWh;
  }

  const costScaling =
    sliderBarDollarPerKWhValue /
    batteryCostData.bevBatteries.baselineDollarPerKWh;
  return costScaling * unAdjustedCost;
}

// Function for estimating re-sale value for a vehicle
export function calculateResaleValue(
  purchaseCost,
  incentives,
  relResidualValueAtFiveYears,
  numYears,
  genSettings
) {
  const annualDepreciation = Math.pow(relResidualValueAtFiveYears, 0.2);
  const valueAdjustment = Math.pow(annualDepreciation, numYears);
  const resaleValue = (purchaseCost - incentives) * valueAdjustment;
  const resaleValueEqAtFirstYear =
    resaleValue / Math.pow(1.0 + genSettings.annualMVInflationRate, numYears);
  return resaleValueEqAtFirstYear;
}

// Provide a clone of slider values, where each value is scaled appropriately for calculations
// Ex. a certain slider might be measuring in dollars, while the calculations need to be done in cents
export function getAdjustedSliderValues(sliderBarValues, sliderBarConfig) {
  const adjustedSliderValues = _.cloneDeep(sliderBarValues);
  for (var key in adjustedSliderValues) {
    adjustedSliderValues[key] *= sliderBarConfig[key]["multiplierToStd"];
  }

  return adjustedSliderValues;
}
