import React from "react";
import Select from "@bit/mui-org.material-ui.select";
import MenuItem from "@bit/mui-org.material-ui.menu-item";
import FormControl from "@bit/mui-org.material-ui.form-control";
import InputLabel from "@bit/mui-org.material-ui.input-label";

const VisualizationDropdowns = ({
  currentDataset,
  onDatasetChange,
  currentPreset,
  onPresetChange,
  presetNames,
  datasetNames,
}) => {
  return (
    <div className="DropdownContainer">
      <FormControl variant="outlined" style={{ width: "40%" }}>
        <InputLabel id="dataset-dropdown-label">Analysis Group</InputLabel>
        <Select
          labelId="dataset-dropdown-label"
          id="dataset-dropdown"
          label="Analysis Group"
          value={currentDataset}
          onChange={onDatasetChange}
        >
          {datasetNames.map((name, index) => {
            if (!name) return null;
            return <MenuItem value={index}>{name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ minWidth: "40%" }}>
        <InputLabel id="preset-dropdown-label">Select Scenario</InputLabel>
        <Select
          labelId="preset-dropdown-label"
          id="preset-dropdown"
          label="Select Scenario"
          value={currentPreset}
          onChange={onPresetChange}
        >
          {presetNames.map((name, index) => {
            if (!name) return null;
            return <MenuItem value={index}>{name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default VisualizationDropdowns;
