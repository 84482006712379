import {
  PowerTrainTypes,
  FuelTypes,
  calculatePowertrainElementCost,
  calculateResaleValue,
  calculateBatteryCost,
  getAdjustedSliderValues,
} from "./CalcUtil";

export function calculateCostValues(
  vehicle,
  sliderBarValues,
  sliderBarConfig,
  genSettings,
  includeMfgGHG
) {
  const adjustedSliderValues = getAdjustedSliderValues(
    sliderBarValues,
    sliderBarConfig
  );

  switch (vehicle.powerTrainType) {
    case PowerTrainTypes.ICE:
      return calculateCosts_ice(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.HEV:
      return calculateCosts_hev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.PHEV:
      return calculateCosts_phev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    case PowerTrainTypes.BEV:
      return calculateCosts_bev(
        vehicle,
        adjustedSliderValues,
        genSettings,
        includeMfgGHG
      );
    // case PowerTrainTypes.COMMERCIAL_BEV:
    //   return calculateXY_bevCommercial(
    //     vehicle,
    //     adjustedSliderValues,
    //     genSettings,
    //     includeMfgGHG
    //   );
    // case PowerTrainTypes.FCEV:
    //   return calculateXY_fcev(
    //     vehicle,
    //     adjustedSliderValues,
    //     genSettings,
    //     includeMfgGHG
    //   );
    // case PowerTrainTypes.FCPHEV:
    //   return calculateXY_fcphev(
    //     vehicle,
    //     adjustedSliderValues,
    //     genSettings,
    //     includeMfgGHG
    //   );
    default:
      return {};
  }
}

function calculateCosts_ice(
  vehicle,
  sliderBarValues,
  genSettings,
  includeManufacturingGhg
) {
  // Calculate engine cost & cost and GHG per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
  }

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe; // Retail purchase equivalent
  const purchaseCost = rpe * vehicle.dollarsBaseVehicle + rpe * engineCost;

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Fuel Amount per Mile
  const fuelAmountPerMile = vehicle.baselineFuelPerMile;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  return {
    retailCost: rpe * vehicle.dollarsBaseVehicle,
    engineCost: rpe * engineCost,
    motorCost: 0,
    batteryCost: 0,
    homeChargerCost: 0,
    reSaleValue: reSaleValue,
    incentives: incentives,
    licensing: vehicle.licensingDollarsPerYear * numYears,
    insurance: vehicle.insuranceDollarsPerYear * numYears,
    maintenance: vehicle.maintenanceDollarPerMile * yearMiles  * numYears,
    fuelCost: annualFuelCost * numYears,
    electricityCost: 0,
    replacementVehicleCost: 0,
  };
}

function calculateCosts_hev(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate engine cost & cost per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
  }

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpe * engineCost +
    rpeElect * (motorCost + batteryCost);

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings,
    includeMfgGHG
  );

  // Fuel Amount per Mile
  const fuelAmountPerMile = vehicle.baselineFuelPerMile;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  return {
    retailCost: rpe * vehicle.dollarsBaseVehicle,
    engineCost: rpe * engineCost,
    motorCost: rpeElect * motorCost,
    batteryCost: rpeElect * batteryCost,
    homeChargerCost: 0,
    reSaleValue: reSaleValue,
    incentives: incentives,
    licensing: vehicle.licensingDollarsPerYear * numYears,
    insurance: vehicle.insuranceDollarsPerYear * numYears,
    maintenance: vehicle.maintenanceDollarPerMile * yearMiles * numYears,
    fuelCost: annualFuelCost * numYears,
    electricityCost: 0,
    replacementVehicleCost: 0,
  };
}

function calculateCosts_phev(
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate engine cost & cost per unit of fuel
  let engineCost = 0;
  let dollarPerUnitFuel = 0;

  if (vehicle.fuelType === FuelTypes.GASOLINE) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_gas,
      vehicle.enigneKW,
      sliderBarValues.engineCost_gas
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_gas;
  } else if (vehicle.fuelType === FuelTypes.DIESEL) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_diesel,
      vehicle.enigneKW,
      sliderBarValues.engineCost_diesel
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_diesel;
  } else if (vehicle.fuelType === FuelTypes.NATURAL_GAS) {
    engineCost = calculatePowertrainElementCost(
      genSettings.engineCostData_ng,
      vehicle.enigneKW,
      sliderBarValues.engineCost_ng
    );
    dollarPerUnitFuel = sliderBarValues.fuelPrice_ng;
  }

  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle +
    rpe * engineCost +
    rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings
  );

  // Adding a home charger setup cost
  const homeChargerCost =
    sliderBarValues.fracCostHomeCharger *
    genSettings.homeChargerAddCost.forPHEVs_L1;

  // Use-phase Fuel and Electricity
  const nonChargersFuelAmountPerMile = vehicle.baselineFuelPerMile;
  const frac_ownersWhoDontCharge = sliderBarValues.bhv_phevsNotCharging;
  const frac_ownersWhoCharge = 1.0 - frac_ownersWhoDontCharge;

  const minDayTimeChargingWindow = sliderBarValues.bhv_minChgDayTimeWindow;
  const frac_overnightOnlyCharging =
    (minDayTimeChargingWindow -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg) /
    (genSettings.presetSliderLimits_dayTimeMinHours.overnightOnly -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg);
  const frac_maxDaytimeCharging = 1.0 - frac_overnightOnlyCharging;

  const frac_optTimingForMinGHG = sliderBarValues.bhv_chgEventTiming;
  const frac_optTimingForMinCost = 1.0 - frac_optTimingForMinGHG;

  const chargers_fuelAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.fuelPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.fuelPerMile;
  const chargers_electAmoutPerMile =
    frac_overnightOnlyCharging * vehicle.bhvRes.overnightOnly.kWhPerMile +
    frac_maxDaytimeCharging * vehicle.bhvRes.maxDayTime.kWhPerMile;

  const fuelAmountPerMile =
    frac_ownersWhoDontCharge * nonChargersFuelAmountPerMile +
    frac_ownersWhoCharge * chargers_fuelAmoutPerMile;
  const eclectricityAmountPerMile =
    frac_ownersWhoCharge * chargers_electAmoutPerMile;

  // Electricity Cost and GHG
  const elect_unitCostModifier =
    frac_overnightOnlyCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.overnightOnly.optCostTiming.electCostMod +
    frac_overnightOnlyCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.overnightOnly.optGHGTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinCost *
      vehicle.bhvRes.maxDayTime.optCostTiming.electCostMod +
    frac_maxDaytimeCharging *
      frac_optTimingForMinGHG *
      vehicle.bhvRes.maxDayTime.optGHGTiming.electCostMod;

  const dollarPerUnitElectricity =
    elect_unitCostModifier * sliderBarValues.fuelPrice_elect;

  // Miles per year
  const yearMiles = sliderBarValues.annualMiles;

  // Annual Cost of fuel
  const annualFuelCost = yearMiles * fuelAmountPerMile * dollarPerUnitFuel;

  // Annual Cost of electricity
  const annualElectricityCost =
    (yearMiles * eclectricityAmountPerMile * dollarPerUnitElectricity) /
    vehicle.chgEff;

  return {
    retailCost: rpe * vehicle.dollarsBaseVehicle,
    engineCost: rpe * engineCost,
    motorCost: rpeElect * motorCost,
    batteryCost: rpeElect * batteryCost,
    homeChargerCost: homeChargerCost,
    reSaleValue: reSaleValue,
    incentives: incentives,
    licensing: vehicle.licensingDollarsPerYear * numYears,
    insurance: vehicle.insuranceDollarsPerYear * numYears,
    maintenance: vehicle.maintenanceDollarPerMile * yearMiles * numYears,
    fuelCost: annualFuelCost * numYears,
    electricityCost: annualElectricityCost * numYears,
    replacementVehicleCost: 0,
  };
}

function calculateCosts_bev( // TODO: Complete this
  vehicle,
  sliderBarValues,
  genSettings,
  includeMfgGHG
) {
  // Calculate motor and battery costs
  const motorCost = calculatePowertrainElementCost(
    genSettings.motorCostData,
    vehicle.motorKW,
    sliderBarValues.motorCost
  );
  const batteryCost = calculateBatteryCost(
    genSettings.batteriesCostData,
    vehicle.batteryKWh,
    sliderBarValues.batteryCost,
    vehicle.powerTrainType
  );

  // Vehicle total purchase cost
  const rpe = sliderBarValues.rpe;
  const rpeElect = sliderBarValues.rpeElect;
  const purchaseCost =
    rpe * vehicle.dollarsBaseVehicle + rpeElect * (motorCost + batteryCost);

  // Incentives
  const incentives = sliderBarValues.fracIncentives * vehicle.dollarsIncentive;

  // Number of years of ownership
  const numYears = sliderBarValues.numYears;

  // Re-sale value
  const reSaleValue = calculateResaleValue(
    purchaseCost,
    incentives,
    vehicle.relResidualValueAtFiveYears,
    numYears,
    genSettings,
    includeMfgGHG
  );

  // Adding a home charger setup cost
  const homeChargerCost =
    sliderBarValues.fracCostHomeCharger *
    genSettings.homeChargerAddCost.forBEVs_L2;

  // User-behavior-dependancies
  const minDayTimeChargingWindow = sliderBarValues.bhv_minChgDayTimeWindow;
  const frac_overnightOnlyCharging =
    (minDayTimeChargingWindow -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg) /
    (genSettings.presetSliderLimits_dayTimeMinHours.overnightOnly -
      genSettings.presetSliderLimits_dayTimeMinHours.maxDayTimeChg);
  const frac_maxDaytimeCharging = 1.0 - frac_overnightOnlyCharging;

  const rangeAnxMiles = sliderBarValues.bhv_BEVRangeAnx;
  const frac_maxRangeAnxBehavior =
    (rangeAnxMiles - genSettings.presetSliderLimits_bevRangeAnx.minAnxMiles) /
    (genSettings.presetSliderLimits_bevRangeAnx.maxAnxMiles -
      genSettings.presetSliderLimits_bevRangeAnx.minAnxMiles);
  const frac_minRangeAnxBehavior = 1.0 - frac_maxRangeAnxBehavior;

  const frac_optTimingForMinGHG = sliderBarValues.bhv_chgEventTiming;
  const frac_optTimingForMinCost = 1.0 - frac_optTimingForMinGHG;

  const frac_bevRep1 = 1.0 - sliderBarValues.bhv_BEVRepVehID;
  const frac_bevRep2 = 1.0 - frac_bevRep1;

  // Four corners interpolation -> nearest 4 sim cases for the inputs
  // get fraction of miles on bev (some wont be satisfied by bev)
  // fraction of days on bev,
  const w11 = frac_overnightOnlyCharging * frac_minRangeAnxBehavior;
  const w12 = frac_overnightOnlyCharging * frac_maxRangeAnxBehavior;
  const w21 = frac_maxDaytimeCharging * frac_minRangeAnxBehavior;
  const w22 = frac_maxDaytimeCharging * frac_maxRangeAnxBehavior;

  const fracMilesOnBEV =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.fracMilesOnBEV +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.fracMilesOnBEV +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.fracMilesOnBEV +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.fracMilesOnBEV;

  const fracDaysOnBEV =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.fracDaysOnBEV +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.fracDaysOnBEV +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.fracDaysOnBEV +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.fracDaysOnBEV;

  const avKWhperMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.avKWhperMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.avKWhperMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.avKWhperMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.avKWhperMile;

  const repVeh1_fuelPerMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.repVeh1_fuelPerMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.repVeh1_fuelPerMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.repVeh1_fuelPerMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.repVeh1_fuelPerMile;
  const repVeh2_fuelPerMile =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.repVeh2_fuelPerMile +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.repVeh2_fuelPerMile +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.repVeh2_fuelPerMile +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.repVeh2_fuelPerMile;

  const chgTiming_optCost_costMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optCostTiming.electCostMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optCostTiming.electCostMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optCostTiming.electCostMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optCostTiming.electCostMod;

  const chgTiming_optGHG_costMod =
    w11 * vehicle.bhvRes.overnightOnly.minAnx.optGHGTiming.electCostMod +
    w12 * vehicle.bhvRes.overnightOnly.maxAnx.optGHGTiming.electCostMod +
    w21 * vehicle.bhvRes.maxDayTime.minAnx.optGHGTiming.electCostMod +
    w22 * vehicle.bhvRes.maxDayTime.maxAnx.optGHGTiming.electCostMod;

  // Use-phase costs and GHG
  const yearMiles = sliderBarValues.annualMiles;
  const yearMilesOnBEV = fracMilesOnBEV * yearMiles;
  const yearMilesOnRepVeh = (1.0 - fracMilesOnBEV) * yearMiles;

  const daysOnRepVeh = (1.0 - fracDaysOnBEV) * sliderBarValues.annualDriveDays;

  const annualFuelRepVeh1 =
    frac_bevRep1 * yearMilesOnRepVeh * repVeh1_fuelPerMile;
  const annualFuelRepVeh2 =
    frac_bevRep2 * yearMilesOnRepVeh * repVeh2_fuelPerMile;

  let annualRepFuelCost = 0.0;

  if (vehicle.fuelTypeRepVeh1 === FuelTypes.GASOLINE) {
    annualRepFuelCost += sliderBarValues.fuelPrice_gas * annualFuelRepVeh1;
  } else if (vehicle.fuelTypeRepVeh1 === FuelTypes.DIESEL) {
    annualRepFuelCost += sliderBarValues.fuelPrice_diesel * annualFuelRepVeh1;
  } else if (vehicle.fuelTypeRepVeh1 === FuelTypes.NATURAL_GAS) {
    annualRepFuelCost += sliderBarValues.fuelPrice_ng * annualFuelRepVeh1;
  }

  if (vehicle.fuelTypeRepVeh2 === FuelTypes.GASOLINE) {
    annualRepFuelCost += sliderBarValues.fuelPrice_gas * annualFuelRepVeh2;
  } else if (vehicle.fuelTypeRepVeh2 === FuelTypes.DIESEL) {
    annualRepFuelCost += sliderBarValues.fuelPrice_diesel * annualFuelRepVeh2;
  } else if (vehicle.fuelTypeRepVeh2 === FuelTypes.NATURAL_GAS) {
    annualRepFuelCost += sliderBarValues.fuelPrice_ng * annualFuelRepVeh2;
  }

  const electCostMod =
    frac_optTimingForMinCost * chgTiming_optCost_costMod +
    frac_optTimingForMinGHG * chgTiming_optGHG_costMod;

  const annualKWhFromGrid = (avKWhperMile * yearMilesOnBEV) / vehicle.chgEff;

  // TODO: Fix this return (unsure which info is relevant)
  return {
    retailCost: rpe * vehicle.dollarsBaseVehicle,
    engineCost: 0,
    motorCost: rpeElect * motorCost,
    batteryCost: rpeElect * batteryCost,
    homeChargerCost: homeChargerCost,
    reSaleValue: reSaleValue,
    incentives: incentives,
    licensing: vehicle.licensingDollarsPerYear * numYears,
    insurance: vehicle.insuranceDollarsPerYear * numYears,
    maintenance: vehicle.maintenanceDollarPerMile * yearMiles * numYears,
    fuelCost: annualRepFuelCost * numYears,
    electricityCost:
      annualKWhFromGrid * sliderBarValues.fuelPrice_elect * electCostMod * numYears,
    replacementVehicleCost:
      genSettings.bevRepVehCost.dollarPerDay * daysOnRepVeh * numYears +
      genSettings.bevRepVehCost.dollarPerMile * yearMilesOnRepVeh * numYears,
  };
}
